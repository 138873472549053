var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('validation-observer',{ref:"formulario"},[_c('b-form',[_c('b-row',[_c('b-col',{attrs:{"md":"4"}},[_c('b-form-group',{attrs:{"label":"Tipo"}},[_c('validation-provider',{attrs:{"name":"Tipo","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select-pt',{attrs:{"id":"selectCadastroTipoLayout","name":"selectCadastroTipoLayout","clearable":false,"options":_vm.opcoesTipo,"reduce":function (option) { return option.value; },"label":"text"},model:{value:(_vm.dados.tipo),callback:function ($$v) {_vm.$set(_vm.dados, "tipo", $$v)},expression:"dados.tipo"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"8"}},[_c('b-form-group',{attrs:{"label":"Nome"}},[_c('validation-provider',{attrs:{"name":"Nome","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-input',{attrs:{"id":"inputCadastroNomeLayout","name":"inputCadastroNomeLayout","placeholder":"Nome do Layout"},model:{value:(_vm.dados.nome),callback:function ($$v) {_vm.$set(_vm.dados, "nome", $$v)},expression:"dados.nome"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"4"}},[_c('b-form-group',{attrs:{"label":"Formato"}},[_c('validation-provider',{attrs:{"name":"Formato","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select-pt',{attrs:{"id":"selectCadastroFormatoLayout","name":"selectCadastroFormatoLayout","clearable":false,"options":_vm.opcoesFormato,"reduce":function (option) { return option.value; },"label":"text"},model:{value:(_vm.dados.formato),callback:function ($$v) {_vm.$set(_vm.dados, "formato", $$v)},expression:"dados.formato"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"8"}},[_c('b-form-group',{attrs:{"label":"Processadora de Margem"}},[_c('validation-provider',{attrs:{"name":"Processadora de Margem","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-input',{attrs:{"id":"inputCadastroProcessadoraDeMargem","name":"inputCadastroProcessadoraDeMargem","placeholder":"Processadora de Margem"},model:{value:(_vm.dados.processadoraDeMargem),callback:function ($$v) {_vm.$set(_vm.dados, "processadoraDeMargem", $$v)},expression:"dados.processadoraDeMargem"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"4"}},[_c('b-form-group',{attrs:{"label":"Possui Cabeçalho","label-for":"possuiCabecalho"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var ariaDescribedby = ref.ariaDescribedby;
return [_c('b-form-radio-group',{staticClass:"w-100",attrs:{"id":"radioLayoutPossuiCabecalho","name":"radioLayoutPossuiCabecalho","options":_vm.optionsSimNao,"aria-describedby":ariaDescribedby,"button-variant":"outline-primary","buttons":""},model:{value:(_vm.dados.possuiCabecalho),callback:function ($$v) {_vm.$set(_vm.dados, "possuiCabecalho", $$v)},expression:"dados.possuiCabecalho"}})]}}])})],1),_c('b-col',{attrs:{"md":"4"}},[_c('b-form-group',{attrs:{"label":"Possui Rodapé","label-for":"possuiRodape"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var ariaDescribedby = ref.ariaDescribedby;
return [_c('b-form-radio-group',{staticClass:"w-100",attrs:{"id":"radioLayoutPossuiRodape","name":"radioLayoutPossuiRodape","options":_vm.optionsSimNao,"aria-describedby":ariaDescribedby,"button-variant":"outline-primary","buttons":""},model:{value:(_vm.dados.possuiRodape),callback:function ($$v) {_vm.$set(_vm.dados, "possuiRodape", $$v)},expression:"dados.possuiRodape"}})]}}])})],1)],1),_c('b-row',{staticClass:"d-flex justify-content-end"},[_c('b-col',{attrs:{"md":"3"}},[_c('b-button',{staticClass:"mt-2",attrs:{"id":"buttonCadastroLayout","name":"buttonCadastroLayout","disabled":_vm.isBusy,"variant":"primary","block":""},on:{"click":_vm.save}},[_vm._v(" "+_vm._s(!_vm.isBusy ? _vm.testoBotao : '')+" "),(_vm.isBusy)?_c('b-spinner',{attrs:{"small":""}}):_vm._e()],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }