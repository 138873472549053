<template>
  <div>
    <b-form ref="form" class="repeater-form" @submit.prevent="novoCampo">
      <!-- Row Loop -->
      <validation-observer ref="rowRulesNumber" tag="form">
        <b-tabs v-model="tabIndex">
          <b-tab v-for="(item, index) in items" :id="item.id" :key="item.id" ref="row" :title="findTabName(item)">
            <b-row>
              <!-- Ordem -->
              <b-col md="2">
                <b-form-group label="Ordem" label-for="ordem">
                  <div class="d-flex align-items-center" style="gap: 0">
                    <b-button
                      style="
                        width: 35px;
                        height: 35px;
                        padding: 0;
                        border-top-right-radius: 0px;
                        border-bottom-right-radius: 0px;
                      "
                      v-b-tooltip.hover.bottom="'Alterar a ordem do campo para a posição anterior'"
                      variant="outline-primary"
                      @click="descendOrder(item, index)"
                      :disabled="item.ordem == 1"
                    >
                      <b-icon icon="arrow-left"></b-icon>
                    </b-button>

                    <b-form-input
                      style="width: 35px; height: 35px; margin: 0; text-align: center; border-radius: 0px; padding: 2px"
                      disabled
                      id="ordem"
                      v-model="item.ordem"
                      type="number"
                      placeholder="1"
                    />

                    <b-button
                      style="width: 35px; height: 35px; padding: 0; border-top-left-radius: 0px; border-bottom-left-radius: 0px"
                      v-b-tooltip.hover.bottom="'Alterar a ordem do campo para a próxima posição'"
                      variant="outline-primary"
                      @click="riseOrder(item, index)"
                      :disabled="item.ordem == items.length"
                    >
                      <b-icon icon="arrow-right"></b-icon>
                    </b-button>
                  </div>
                </b-form-group>
              </b-col>

              <!-- Campo -->
              <b-col md="6">
                <b-form-group label="Campo" label-for="campoSelect">
                  <validation-provider #default="{ errors }" name="Campo" rules="required">
                    <v-select-pt
                      id="campoSelect"
                      inputId="campoInputSelect"
                      name="campoSelect"
                      v-model="item.campo"
                      :options="fieldsList"
                      :reduce="(option) => option.value"
                      placeholder="Selecionar campo"
                      label="text"
                      :clearable="false"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>

              <!-- Tipo -->
              <b-col md="4">
                <b-form-group label="Tipo" label-for="tipoSelect">
                  <validation-provider #default="{ errors }" name="Tipo" rules="required">
                    <v-select-pt
                      id="tipoSelect"
                      name="tipoSelect"
                      v-model="item.tipo"
                      :options="typeList"
                      :reduce="(option) => option.value"
                      placeholder="Selecionar tipo"
                      label="text"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>

              <!-- Formatações -->
              <b-col md="6">
                <b-form-group label="Formatações" label-for="formatacoes">
                  <v-select
                    id="formatacoesSelect"
                    name="formatacoesSelect"
                    v-model="item.formatacoes"
                    multiple
                    :options="formatList"
                    placeholder="Selecione a formatação do campo"
                    label="text"
                  >
                    <template #no-options>
                      <div class="my-no-options-message">Nenhum registro encontrado!</div>
                    </template>

                    <template v-slot:option="option">
                      <span v-b-tooltip.hover.top="option.title" class="d-block w-100">
                        {{ option.text }}
                      </span>
                    </template>
                  </v-select>
                </b-form-group>
              </b-col>

              <!-- Tamanho -->
              <b-col md="2">
                <b-form-group label="Tamanho" label-for="tamanho">
                  <validation-provider #default="{ errors }" name="Tamanho" rules="required">
                    <b-form-input
                      id="tamanho"
                      v-model="item.tamanho"
                      type="number"
                      placeholder="1"
                      @blur="updatePositions(item)"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>

              <!-- Posição Inicial -->
              <b-col md="2">
                <b-form-group label="Posição Inicial" label-for="posicaoInicial">
                  <b-form-input disabled id="posicaoInicial" v-model="item.posicaoInicial" type="number" placeholder="1" />
                </b-form-group>
              </b-col>

              <!-- Posição Final -->
              <b-col md="2">
                <b-form-group label="Posição Final" label-for="posicaoFinal">
                  <b-form-input disabled id="posicaoFinal" v-model="item.posicaoFinal" type="number" placeholder="1" />
                </b-form-group>
              </b-col>

              <!-- Descrição -->
              <b-col md="12">
                <b-form-group label="Descrição" label-for="descricao">
                  <b-form-input id="descricao" v-model="item.descricao" type="text" />
                </b-form-group>
              </b-col>

              <!-- Remove Button -->
              <b-col lg="auto" md="auto" class="mb-50">
                <b-button
                  v-ripple.400="'rgba(234, 84, 85, 0.15)'"
                  style="width: 160px"
                  v-if="items.length > 1"
                  variant="danger"
                  class="mt-0 mt-md-2"
                  @click="removeItem(index)"
                >
                  <span>Excluir Campo</span>
                </b-button>
              </b-col>
            </b-row>
          </b-tab>

          <!-- New Tab Button (Using tabs-end slot) -->
          <template #tabs-end>
            <b-nav-item role="presentation" href="#" @click="novoCampo"><b>+ Adicionar Campo</b></b-nav-item>
          </template>

          <!-- Render this if no tabs -->
          <template #empty>
            <div class="text-center text-muted">
              Nenhum campo para o {{ origem }} foi encontrado<br />
              Clique em <b>+ Novo Campo</b> para adicionar.
            </div>
          </template>
        </b-tabs>
      </validation-observer>
    </b-form>

    <b-row class="justify-content-end">
      <b-col md="auto" class="ml-auto">
        <b-button class="mt-1" v-ripple.400="'rgba(255, 255, 255, 0.15)'" style="width: 160px" variant="success" @click="save">
          <span>Salvar</span>
        </b-button>
      </b-col>
    </b-row>
  </div>
</template>

<script>
  import useJwt from '@/auth/jwt/useJwt'
  import BCardCode from '@core/components/b-card-code/BCardCode.vue'
  import { ValidationObserver, ValidationProvider } from 'vee-validate'
  import Ripple from 'vue-ripple-directive'
  import VSelect from 'vue-select'

  export default {
    components: {
      ValidationProvider,
      ValidationObserver,
      VSelect,
      BCardCode,
    },
    props: {
      origem: {
        type: String,
        default: '',
      },
      layoutId: {
        type: Number,
        default: 1,
      },
    },
    directives: {
      Ripple,
    },
    data() {
      return {
        userData: this.$jwtDecode(localStorage.getItem('userData')).userData,
        isBusy: false,
        fieldsList: [],
        typeList: [
          { value: 'text', text: 'Texto' },
          { value: 'number', text: 'Número' },
          { value: 'date', text: 'Data' },
          { value: 'datetime', text: 'Data e Hora' },
          { value: 'boolean', text: 'Booleano' },
        ],
        formatList: [
          { value: 'capitalizar', text: 'Capitalizar', title: 'Converte todo o texto para maiúsculas.' },
          {
            value: 'cpfCnpj',
            text: 'CPF/CNPJ',
            title: 'Formata para XXX.XXX.XXX-XX (CPF) ou XX.XXX.XXX/YYYY-ZZ (CNPJ).',
          },
          {
            value: 'dataAbreviada',
            text: 'Data Abreviada',
            title: 'Formato dd/mmm/yyyy, onde mmm é a abreviação do mês.',
          },
          { value: 'dataCompleta', text: 'Data Completa', title: 'Formato dd/mm/yyyy ou yyyy-mm-dd.' },
          {
            value: 'email',
            text: 'Email',
            title: 'Valida o formato de email, mas não aplica uma formatação visual específica.',
          },
          {
            value: 'formatoCNPJ',
            text: 'Formatação de CNPJ',
            title: 'Formata o texto para o padrão de CNPJ (##.###.###/####-##).',
          },
          {
            value: 'formatoCPF',
            text: 'Formatação de CPF',
            title: 'Formata o texto para o padrão de CPF (###.###.###-##).',
          },
          {
            value: 'formatoData',
            text: 'Formatação de Data',
            title: 'Converte o texto para o padrão de data (dd/MM/yyyy).',
          },
          {
            value: 'formatoMoeda',
            text: 'Formatação de Moeda',
            title: 'Converte o texto para o formato de moeda (ex: 1234 vira R$ 1.234,00).',
          },
          { value: 'formatoRG', text: 'Formatação de RG', title: 'Formata o texto para o padrão de RG (##.###.###-#).' },
          {
            value: 'formatoTelefone',
            text: 'Formatação de Telefone',
            title: 'Formata o texto para o padrão de telefone (##) #####-####.',
          },
          {
            value: 'horaCompleta',
            text: 'Hora Completa',
            title: 'Formato hh:mm:ss para 24 horas ou hh:mm:ss tt para 12 horas com AM/PM.',
          },
          {
            value: 'letrasNumerosApenas',
            text: 'Letras e Números Apenas',
            title: 'Remove qualquer coisa que não seja letra ou número.',
          },
          {
            value: 'moeda',
            text: 'Moeda',
            title: 'Adiciona símbolo de moeda e formata com duas casas decimais, por exemplo, $1,234.56.',
          },
          { value: 'numeroTelefone', text: 'Número Telefone', title: 'Formata para (XX) X XXXX-XXXX.' },
          {
            value: 'porcentagem',
            text: 'Porcentagem',
            title: 'Multiplica o número por 100 e adiciona o símbolo %, por exemplo, 50%.',
          },
          {
            value: 'primeiraLetraMaiuscula',
            text: 'Primeira Letra Maiúscula',
            title: 'Converte a primeira letra do texto para maiúscula, mantendo o restante em minúsculas.',
          },
          {
            value: 'removerMultiplosEspacos',
            text: 'Remover Múltiplos Espaços',
            title: 'Substitui múltiplos espaços consecutivos por um único espaço.',
          },
          {
            value: 'semAcentuacao',
            text: 'Sem Acentuação',
            title: 'Remove todos os acentos e caracteres especiais típicos de línguas com acentuação.',
          },
          {
            value: 'semCaracteresEspeciais',
            text: 'Sem Caracteres Especiais',
            title: 'Remove todos os caracteres especiais, mantendo apenas letras e números.',
          },
          { value: 'semEspacos', text: 'Sem Espaços', title: 'Remove todos os espaços do texto.' },
          {
            value: 'semNumeros',
            text: 'Sem Números',
            title: 'Remove todos os números do texto, mantendo apenas letras.',
          },
          {
            value: 'slugify',
            text: 'Slugify',
            title: 'Converte o texto para um formato de URL amigável, removendo espaços, acentos e caracteres especiais.',
          },
          { value: 'somenteNumeros', text: 'Somente Números', title: 'Permite apenas a entrada de dígitos numéricos.' },
          { value: 'trim', text: 'Trim', title: 'Remove os espaços em branco no início e no fim do texto.' },
          { value: 'tudoMaiusculas', text: 'Tudo em Maiúsculas', title: 'Converte todos os caracteres para maiúsculas.' },
          { value: 'tudoMinusculas', text: 'Tudo em Minúsculas', title: 'Converte todos os caracteres para minúsculas.' },
          { value: 'url', text: 'URL', title: 'Transforma o texto em um hyperlink clicável.' },
        ],
        items: [
          {
            campo: null,
            ordem: 1,
            tipo: null,
            tamanho: null,
            posicaoInicial: null,
            posicaoFinal: null,
            formatacoes: [],
            descricao: '',
          },
        ],
        tabIndex: 0,
      }
    },
    async mounted() {
      await this.getTags()
      await this.getFields()
    },
    methods: {
      async getTags() {
        this.isBusy = true
        const institutoId = this.userData.institutoSelecionado

        await useJwt
          .pesquisar('tag/Getpesquisar', {
            institutoSelecionado: institutoId,
          })
          .then((response) => {
            const list = []
            const data = response.data.dados.map((item) => {
              return {
                value: item.id,
                text: item.nome,
              }
            })

            list.push(...data)
            this.fieldsList = list
          })
          .catch((error) => {
            console.error(error)
          })
          .finally(() => {
            this.isBusy = false
          })
      },
      async getFields() {
        this.isBusy = true
        await useJwt
          .get(`layout/getFields/${this.layoutId}/${this.origem}`)
          .then((response) => {
            if (response.data.length) {
              this.items = response.data.map((item) => {
                return {
                  campo: item.tagId,
                  ordem: item.ordem,
                  tipo: item.tipo,
                  tamanho: item.tamanho,
                  posicaoInicial: item.posicaoInicial,
                  posicaoFinal: item.posicaoFinal,
                  formatacoes: item.formatacoes,
                  descricao: item.descricao,
                }
              })
            }
          })
          .catch((error) => {
            console.error(error)
          })
          .finally(() => {
            this.isBusy = false
          })
      },
      novoCampo() {
        this.$refs.rowRulesNumber.validate().then((success) => {
          if (success) {
            const itemAnterior = this.items[this.items.length - 1]
            const lastPosition = itemAnterior.ordem
            const firstPosition = itemAnterior.posicaoFinal + 1

            this.items.push({
              campo: null,
              ordem: lastPosition + 1,
              tipo: null,
              tamanho: null,
              posicaoInicial: firstPosition,
              posicaoFinal: 0,
              formatacoes: [],
              descricao: '',
            })

            this.$nextTick(() => {
              this.tabIndex = 0
            })
          }
        })
      },
      reOrder() {
        let itemAnterior = 1
        this.items.forEach((item, index) => {
          item.ordem = index + 1
          item.posicaoInicial = index != 0 ? itemAnterior.posicaoFinal + 1 : 1
          item.posicaoFinal = Number(item.posicaoInicial) + Number(item.tamanho) - 1
          itemAnterior = item
        })
      },
      removeItem(index) {
        this.items.splice(index, 1)
        this.reOrder()
      },
      riseOrder(item, index) {
        this.items[index + 1].ordem = this.items[index + 1].ordem - 1
        item.ordem = item.ordem + 1
        this.items.sort((a, b) => a.ordem - b.ordem)
        this.reOrder()
      },
      descendOrder(item, index) {
        this.items[index - 1].ordem = this.items[index - 1].ordem + 1
        item.ordem = item.ordem - 1
        this.items.sort((primeiroItem, segundoItem) => primeiroItem.ordem - segundoItem.ordem)
        this.reOrder()
      },
      save() {
        this.$refs.rowRulesNumber.validate().then((success) => {
          if (success) {
            if (!this.items.length) return this.$message.error('Insira ao menos um campo para salvar')

            const fieldList = this.items.map((item) => {
              return {
                campo: item.campo,
                ordem: item.ordem,
                tipo: item.tipo,
                tamanho: Number(item.tamanho),
                posicaoInicial: item.posicaoInicial,
                posicaoFinal: item.posicaoFinal,
                formatacoes: item.formatacoes,
                descricao: item.descricao,
                origem: this.origem,
              }
            })

            const parametro = {
              institutoId: this.userData.institutoSelecionado,
              layoutId: this.layoutId,
              origem: this.origem,
              campos: fieldList,
            }

            useJwt
              .put('layout/createFields', parametro)
              .then(() => {
                this.$message.success('Layout cadastrado com sucesso!')
                this.$emit('closeModal')
              })
              .catch((error) => {
                console.error(error)
                this.$message.error('Erro ao cadastrar layout!')
              })
              .finally(() => {
                this.isBusy = false
              })
          } else this.$message.error('Preencha todos os campos obrigatórios!')
        })
      },
      findTabName(item) {
        let title = 'Novo Campo'
        if (item.campo) {
          const campo = this.fieldsList.find((field) => field.value === item.campo).text
          title = campo.replace(/[{}]/g, '')
        }
        return title
      },
      updatePositions(item) {
        const index = this.items.findIndex((i) => i === item)
        const lastPosition = this.items[index - 1]?.posicaoFinal || 0
        const size = item.tamanho
        item.posicaoInicial = Number(lastPosition) + 1
        item.posicaoFinal = Number(lastPosition) + Number(size)
      },
    },
  }
</script>

<style lang="scss" scoped>
  .repeater-form {
    transition: 0.35s height;
  }

  div#campoSelect ul.vs__dropdown-menu {
    position: relative;
    background-color: red;
  }

  .v-select.drop-up.vs--open .vs__dropdown-toggle {
    border-radius: 0 0 4px 4px;
    border-top-color: transparent;
    border-bottom-color: rgba(60, 60, 60, 0.26);
  }

  [data-popper-placement='top'] {
    border-radius: 4px 4px 0 0;
    border-top-style: solid;
    border-bottom-style: none;
    box-shadow: 0 -3px 6px rgba(0, 0, 0, 0.15);
  }

  #campoInputSelect,
  div#modalCadastroCampos div.card-header {
    display: none !important;
  }

  div#campoSelect {
    height: 37px !important;
  }

  div#campoSelect [role='combobox'] {
    width: max-content;
  }
</style>
