<template>
  <validation-observer ref="formulario">
    <b-form>
      <b-row>
        <b-col md="4">
          <b-form-group label="Tipo">
            <validation-provider #default="{ errors }" name="Tipo" rules="required">
              <v-select-pt
                id="selectCadastroTipoLayout"
                name="selectCadastroTipoLayout"
                :clearable="false"
                :options="opcoesTipo"
                :reduce="(option) => option.value"
                v-model="dados.tipo"
                label="text"
              ></v-select-pt>
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
        </b-col>

        <b-col md="8">
          <b-form-group label="Nome">
            <validation-provider #default="{ errors }" name="Nome" rules="required">
              <b-input
                id="inputCadastroNomeLayout"
                name="inputCadastroNomeLayout"
                v-model="dados.nome"
                placeholder="Nome do Layout"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
        </b-col>

        <b-col md="4">
          <b-form-group label="Formato">
            <validation-provider #default="{ errors }" name="Formato" rules="required">
              <v-select-pt
                id="selectCadastroFormatoLayout"
                name="selectCadastroFormatoLayout"
                :clearable="false"
                :options="opcoesFormato"
                :reduce="(option) => option.value"
                v-model="dados.formato"
                label="text"
              ></v-select-pt>
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
        </b-col>

        <b-col md="8">
          <b-form-group label="Processadora de Margem">
            <validation-provider #default="{ errors }" name="Processadora de Margem" rules="required">
              <b-input
                id="inputCadastroProcessadoraDeMargem"
                name="inputCadastroProcessadoraDeMargem"
                v-model="dados.processadoraDeMargem"
                placeholder="Processadora de Margem"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
        </b-col>

        <b-col md="4">
          <b-form-group label="Possui Cabeçalho" label-for="possuiCabecalho" v-slot="{ ariaDescribedby }">
            <b-form-radio-group
              id="radioLayoutPossuiCabecalho"
              name="radioLayoutPossuiCabecalho"
              v-model="dados.possuiCabecalho"
              :options="optionsSimNao"
              :aria-describedby="ariaDescribedby"
              button-variant="outline-primary"
              class="w-100"
              buttons
            ></b-form-radio-group>
          </b-form-group>
        </b-col>

        <b-col md="4">
          <b-form-group label="Possui Rodapé" label-for="possuiRodape" v-slot="{ ariaDescribedby }">
            <b-form-radio-group
              id="radioLayoutPossuiRodape"
              name="radioLayoutPossuiRodape"
              v-model="dados.possuiRodape"
              :options="optionsSimNao"
              :aria-describedby="ariaDescribedby"
              button-variant="outline-primary"
              class="w-100"
              buttons
            ></b-form-radio-group>
          </b-form-group>
        </b-col>
      </b-row>

      <b-row class="d-flex justify-content-end">
        <b-col md="3">
          <b-button
            id="buttonCadastroLayout"
            name="buttonCadastroLayout"
            :disabled="isBusy"
            class="mt-2"
            variant="primary"
            @click="save"
            block
          >
            {{ !isBusy ? testoBotao : '' }}
            <b-spinner small v-if="isBusy" />
          </b-button>
        </b-col>
      </b-row>
    </b-form>
  </validation-observer>
</template>

<script>
  import { ValidationObserver, ValidationProvider } from 'vee-validate'
  import useJwt from '@/auth/jwt/useJwt'

  export default {
    name: 'ModalCadastroLayout',
    components: {
      ValidationObserver,
      ValidationProvider,
    },
    directives: {},
    props: {
      parametro: {
        type: Object,
      },
    },
    data() {
      return {
        userData: this.$jwtDecode(localStorage.getItem('userData')).userData,
        isBusy: false,
        opcoesTipo: [
          { value: null, text: 'Selecione' },
          { value: 'layoutMovimento', text: 'Layout de Movimento' },
          { value: 'layoutImportacao', text: 'Layout de Importação' },
          { value: 'layoutExportacao', text: 'Layout de Exportação' },
        ],
        opcoesFormato: [
          { value: null, text: 'Selecione' },
          { value: 'txt', text: 'TXT' },
          { value: 'json', text: 'JSON' },
          { value: 'xls', text: 'XLS' },
        ],
        optionsSimNao: [
          { text: 'Sim', value: true },
          { text: 'Não', value: false },
        ],
        dados: {
          tipo: null,
          formato: null,
          nome: null,
          processadoraDeMargem: null,
          possuiCabecalho: false,
          possuiRodape: false,
        },
        testoBotao: 'Cadastrar',
      }
    },
    computed: {},
    mounted() {
      this.$nextTick(() => {
        if (this.parametro?.id) {
          this.dados = {
            id: this.parametro.id,
            tipo: this.parametro.tipo,
            formato: this.parametro.formato,
            nome: this.parametro.nome,
            processadoraDeMargem: this.parametro.processadoraDeMargem,
            possuiCabecalho: this.parametro.possuiCabecalho,
            possuiRodape: this.parametro.possuiRodape,
          }

          this.testoBotao = 'Alterar'
        }
      })
    },
    methods: {
      save() {
        this.$refs.formulario.validate().then((success) => {
          if (success) {
            if (this.parametro?.id) {
              this.editarLayout()
            } else {
              this.cadastrar()
            }
          }
        })
      },
      cadastrar() {
        this.isBusy = true
        const param = {
          tipo: this.dados.tipo,
          formato: this.dados.formato,
          nome: this.dados.nome,
          processadoraDeMargem: this.dados.processadoraDeMargem,
          institutoId: this.userData.institutoSelecionado,
          possuiCabecalho: this.dados.possuiCabecalho,
          possuiRodape: this.dados.possuiRodape,
        }

        useJwt
          .post('layout', param)
          .then(() => {
            this.$message.success('Layout cadastrado com sucesso!')
            this.$emit('closeModal')
          })
          .catch((error) => {
            console.error(error)
            this.$message.error('Erro ao cadastrar layout!')
          })
          .finally(() => {
            this.isBusy = false
          })
      },
      editarLayout() {
        const param = {
          tipo: this.dados.tipo,
          formato: this.dados.formato,
          nome: this.dados.nome,
          processadoraDeMargem: this.dados.processadoraDeMargem,
          institutoId: this.userData.institutoSelecionado,
          possuiCabecalho: this.dados.possuiCabecalho,
          possuiRodape: this.dados.possuiRodape,
        }

        useJwt
          .patch(`layout/${this.dados.id}`, param)
          .then(() => {
            this.$message.success('Layout alterado com sucesso!')
            this.$emit('closeModal')
          })
          .catch((error) => {
            console.error(error)
            this.$message.error('Erro ao alterar layout!')
          })
          .finally(() => {
            this.isBusy = false
          })
      },
    },
  }
</script>

<style lang="scss">
  @import '@core/scss/vue/libs/vue-select.scss';
</style>
