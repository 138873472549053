<template>
  <div>
    <b-tabs>
      <!--Configurações Gerais-->
      <b-tab active>
        <template #title>
          <feather-icon icon="UploadCloudIcon" size="18" class="mr-50" />
          <span class="font-weight-bold">Importar Margem Consignável</span>
        </template>
        <ImportarExportarMargemImport v-if="options" :general-data="options" />
      </b-tab>

      <!--Configurações Gerais-->
      <b-tab>
        <template #title>
          <feather-icon icon="UploadCloudIcon" size="18" class="mr-50" />
          <span class="font-weight-bold">Importar Retorno</span>
        </template>
        <ImportarExportarMargemImport v-if="options" :general-data="options" />
      </b-tab>

      <!--Mudar a senha-->
      <b-tab>
        <template #title>
          <feather-icon icon="DownloadCloudIcon" size="18" class="mr-50" />
          <span class="font-weight-bold">Exportar Margem Consignável</span>
        </template>
        <ImportarExportarMargemExport v-if="options" :general-data="options" />
      </b-tab>
    </b-tabs>
  </div>
</template>

<script>
  import ImportarExportarMargemExport from './ImportarExportarMargemExport.vue'
  import ImportarExportarMargemImport from './ImportarExportarMargemImport.vue'

  import useJwt from '@/auth/jwt/useJwt'

  export default {
    components: {
      ImportarExportarMargemExport,
      ImportarExportarMargemImport,
    },
    data() {
      return {
        options: null,
        userData: this.$jwtDecode(localStorage.getItem('userData')).userData,
      }
    },
    mounted() {
      this.carregarGrid()
    },
    methods: {
      carregarGrid() {
        useJwt
          .get(`cadastro/instituto/${this.userData.institutoSelecionado}`)
          .then((response) => {
            this.options = response.data
          })
          .catch((error) => {
            console.error(error)
          })
      },
    },
  }
</script>
