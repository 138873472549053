<template>
  <div>
    <b-tabs pills>
      <b-tab title="Margem Consignável">
        <b-card-text>
          <ImportarExportarMargem />
        </b-card-text>
      </b-tab>
      <b-tab title="Cadastros">
        <b-card-text>
          <ImportarExportarCadastros />
        </b-card-text>
      </b-tab>
      <b-tab title="Layout Modular">
        <b-card-text>
          <LayoutModular />
        </b-card-text>
      </b-tab>
    </b-tabs>
  </div>
</template>

<script>
  import ImportarExportarCadastros from './ImportarExportarCadastros.vue'
  import ImportarExportarMargem from './ImportarExportarMargem.vue'
  import LayoutModular from './LayoutModular/LayoutModular.vue'

  export default {
    components: {
      ImportarExportarCadastros,
      ImportarExportarMargem,
      LayoutModular,
    },
    data() {
      return {
        userData: this.$jwtDecode(localStorage.getItem('userData')).userData,
      }
    },
  }
</script>
