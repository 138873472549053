import { render, staticRenderFns } from "./ImportarExportarMargemImport.vue?vue&type=template&id=41555a23"
import script from "./ImportarExportarMargemImport.vue?vue&type=script&lang=js"
export * from "./ImportarExportarMargemImport.vue?vue&type=script&lang=js"
import style0 from "./ImportarExportarMargemImport.vue?vue&type=style&index=0&id=41555a23&prod&lang=css"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports