<template>
  <div>
    <b-tabs>
      <!--Configurações Gerais-->
      <b-tab active>
        <template #title>
          <feather-icon icon="UploadCloudIcon" size="18" class="mr-50" />
          <span class="font-weight-bold">Importar</span>
        </template>
        <ImportarExportarCadastrosImport v-if="options" :general-data="options" />
      </b-tab>

      <!--Mudar a senha-->
      <b-tab>
        <template #title>
          <feather-icon icon="DownloadCloudIcon" size="18" class="mr-50" />
          <span class="font-weight-bold">Exportar</span>
        </template>
        <ImportarExportarCadastrosExport v-if="options" :general-data="options" />
      </b-tab>
    </b-tabs>
  </div>
</template>

<script>
  import ImportarExportarCadastrosImport from './ImportarExportarCadastrosImport.vue'
  import ImportarExportarCadastrosExport from './ImportarExportarCadastrosExport.vue'
  import useJwt from '@/auth/jwt/useJwt'

  export default {
    components: {
      ImportarExportarCadastrosImport,
      ImportarExportarCadastrosExport,
    },
    data() {
      return {
        options: null,
        userData: this.$jwtDecode(localStorage.getItem('userData')).userData,
      }
    },
    mounted() {
      this.carregarGrid()
    },
    methods: {
      carregarGrid() {
        useJwt
          .get(`cadastro/instituto/${this.userData.institutoSelecionado}`)
          .then((response) => {
            this.options = response.data
          })
          .catch((error) => {
            console.error(error)
          })
      },
    },
  }
</script>
