<template>
  <div>
    <b-card-code no-body v-if="acesso.CadastroLayoutModularConsulta">
      <b-card-body>
        <b-row>
          <b-col md="2">
            <b-form-group label="Tipo" label-for="tipo">
              <v-select-pt
                id="selectFiltroTipo"
                name="selectFiltroTipo"
                :clearable="false"
                :options="opcoesTipoLayout"
                :reduce="(option) => option.value"
                v-model="pesquisar.tipoLayout"
                label="text"
              ></v-select-pt>
            </b-form-group>
          </b-col>

          <b-col md="2">
            <b-form-group label="Pesquisar Por" label-for="opcao">
              <v-select-pt
                id="selectFiltroPesquisaPor"
                name="selectFiltroPesquisaPor"
                :clearable="false"
                label="text"
                v-model="pesquisar.tipoOpcoes"
                :options="tipoOpcoes"
                :reduce="(option) => option.value"
              ></v-select-pt>
            </b-form-group>
          </b-col>

          <b-col md="4">
            <b-form-group label="Descrição" label-for="descricao">
              <b-form-input
                id="descricao"
                placeholder="Descrição"
                v-model="pesquisar.descricao"
                v-on:keydown.enter="carregarGrid"
              />
            </b-form-group>
          </b-col>

          <b-col md="auto">
            <b-button variant="primary" @click="carregarGrid" :disabled="isBusy" class="botaoPesquisar">
              {{ !isBusy ? 'Pesquisar' : '' }}
              <b-spinner small v-if="isBusy" />
            </b-button>
          </b-col>

          <b-col md="auto" align="right" v-show="this.acesso.CadastroLayoutCriarNovo">
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              class="botaoPesquisar"
              @click="novoRegistro()"
            >
              Novo Cadastro
            </b-button>
          </b-col>
        </b-row>
      </b-card-body>

      <b-table
        v-show="this.totalRows > 0"
        striped
        responsive
        class="position-relative"
        :per-page="perPage"
        :items="items"
        :fields="fields"
        :busy="isBusy"
        :current-page="currentPage"
      >
        <template v-slot:table-busy>
          <div class="text-center my-2">
            <b-spinner class="align-middle mr-1"></b-spinner>
            <strong>Carregando...</strong>
          </div>
        </template>

        <template #cell(opcoes)="row">
          <b-dropdown variant="link" no-caret toggle-class="p-0" left :popper-opts="{ positionFixed: true }">
            <template #button-content>
              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="primary"
                class="btn-icon"
                title="Este botão contém as opções disponíveis para este registro"
              >
                <feather-icon icon="SettingsIcon" />
              </b-button>
            </template>

            <b-dropdown-item
              :disabled="!acesso.CadastroLayoutEditar"
              v-b-tooltip.hover.left="acesso.CadastroLayoutEditar ? textoCadastroCabecalho : opcaoDesabilitada"
              @click="abrirCadastroCabecalho(row.item.id)"
              v-show="acesso.CadastroLayoutEditar"
            >
              <feather-icon icon="EditIcon" size="16" />
              <span class="align-middle ml-50">Campos do Cabeçalho</span>
            </b-dropdown-item>

            <b-dropdown-item
              :disabled="!acesso.CadastroLayoutEditar"
              v-b-tooltip.hover.left="acesso.CadastroLayoutEditar ? textoCadastroCorpo : opcaoDesabilitada"
              @click="abrirCadastroCorpo(row.item.id)"
              v-show="acesso.CadastroLayoutEditar"
            >
              <feather-icon icon="EditIcon" size="16" />
              <span class="align-middle ml-50">Campos do Corpo</span>
            </b-dropdown-item>

            <b-dropdown-item
              :disabled="!acesso.CadastroLayoutEditar"
              v-b-tooltip.hover.left="acesso.CadastroLayoutEditar ? textoCadastroRodape : opcaoDesabilitada"
              @click="abrirCadastroRodape(row.item.id)"
              v-show="acesso.CadastroLayoutEditar"
            >
              <feather-icon icon="EditIcon" size="16" />
              <span class="align-middle ml-50">Campos do Rodapé</span>
            </b-dropdown-item>

            <b-dropdown-item @click="editar(row.item)" v-show="acesso.CadastroLayoutEditar">
              <feather-icon icon="EditIcon" size="16" />
              <span class="align-middle ml-50">Editar</span>
            </b-dropdown-item>

            <b-dropdown-item @click="authEExclui(row.item.id)" v-show="acesso.CadastroLayoutExcluir">
              <feather-icon icon="XIcon" size="16" />
              <span class="align-middle ml-50">Excluir</span>
            </b-dropdown-item>
          </b-dropdown>
        </template>
      </b-table>

      <b-card-body v-if="this.totalRows > 0" class="d-flex justify-content-between flex-wrap pt-0">
        <!-- page length -->
        <b-form-group
          label="Por Página"
          style="align-items: center"
          label-cols="6"
          label-align="left"
          label-size="sm"
          label-for="sortBySelect"
          class="text-nowrap mb-md-0 mr-1"
        >
          <b-form-select id="perPageSelect" v-model="perPage" size="sm" inline :options="pageOptions" />
        </b-form-group>

        <!-- pagination -->
        <b-pagination
          v-model="currentPage"
          :total-rows="totalRows"
          :per-page="perPage"
          prev-class="prev-item"
          next-class="next-item"
          class="mb-0"
        >
          <template #prev-text>
            <feather-icon icon="ChevronLeftIcon" size="18" />
          </template>
          <template #next-text>
            <feather-icon icon="ChevronRightIcon" size="18" />
          </template>
        </b-pagination>
      </b-card-body>

      <!-- Modal Cadastro -->
      <b-modal
        id="modal-cadastro"
        ref="modal-cadastro"
        centered
        size="lg"
        :title="tituloModal"
        no-close-on-backdrop
        hide-footer
      >
        <ModalCadastroLayout :parametro="dados" @closeModal="fecharModal" />
      </b-modal>

      <!-- Modal Cadastro De Campos -->
      <b-modal
        id="modalCadastroCampos"
        ref="modalCadastroCampos"
        centered
        :title="tituloModalCadastroCampos"
        no-close-on-backdrop
        no-close-on-esc
        hide-footer
        size="lg"
      >
        <ModalCadastroCamposLayout :layoutId="layoutId" :origem="origem" @closeModal="fecharModalCadastroDeCampos" />
      </b-modal>

      <!-- Valida Senha -->
      <b-modal id="modal-auth-senha" ref="modal-auth-senha" centered size="sm" title="Autenticação" hide-footer>
        <AutenticaSenha @autenticarEvent="autenticarMethod" />
      </b-modal>
    </b-card-code>

    <b-card class="text-center" v-else>
      <b-card-body>
        <b-icon icon="exclamation-triangle" variant="danger" font-scale="2"></b-icon>
        <b-card-title class="text-danger mt-2">Acesso Negado!</b-card-title>
        <b-card-text class="mb-1">Parece que seu usuário não tem permissão para realizar pesquisas.</b-card-text>
        <b-card-text class="mb-1">
          Para obter acesso, por favor, solicite assistência ou permissões adicionais ao administrador do sistema.
        </b-card-text>
      </b-card-body>
    </b-card>
  </div>
</template>

<script>
  import useJwt from '@/auth/jwt/useJwt'
  import AutenticaSenha from '@/views/pages/auth/autenticaSenha'
  import BCardCode from '@core/components/b-card-code/BCardCode.vue'
  import { required } from '@validations'
  import 'cleave.js/dist/addons/cleave-phone.br'
  import { ValidationObserver, ValidationProvider } from 'vee-validate'
  import Cleave from 'vue-cleave-component'
  import Ripple from 'vue-ripple-directive'
  import ModalCadastroCamposLayout from './components/CadastroDeCampos/ModalCadastroCamposLayout.vue'
  import ModalCadastroLayout from './components/CadastroDeLayout/ModalCadastroLayout.vue'

  export default {
    components: {
      BCardCode,
      ValidationProvider,
      ValidationObserver,
      Cleave,
      AutenticaSenha,
      ModalCadastroLayout,
      ModalCadastroCamposLayout,
    },
    directives: {
      Ripple,
    },
    data() {
      return {
        userData: this.$jwtDecode(localStorage.getItem('userData')).userData,
        isBusy: false,
        pesquisar: {
          tipoOpcoes: 'nome',
          tipoLayout: '',
          descricao: '',
        },
        perPage: 10,
        currentPage: 1,
        isBusy: false,
        required,
        number: {},
        origem: '',
        layoutId: null,
        opcoesTipoLayout: [
          { value: '', text: 'Todos' },
          { value: 'layoutMovimento', text: 'Layout de Movimento' },
          { value: 'layoutImportacao', text: 'Layout de Importação' },
          { value: 'layoutExportacao', text: 'Layout de Exportação' },
        ],
        tipoOpcoes: [
          { value: 'nome', text: 'Nome' },
          { value: 'processadoraDeMargem', text: 'Processadora de Margem' },
        ],
        options: {
          cleaveDia: {
            numeral: true,
            numeralIntegerScale: 2,
            delimiter: '',
            blocks: [2],
          },
          cleaveCodigo: {
            numeral: true,
            numeralIntegerScale: 2,
            delimiter: '',
            blocks: [2],
          },
        },
        pageOptions: [10, 25, 50, 100],
        totalRows: 0,
        fields: [
          { key: 'opcoes', label: 'Opções' },
          { key: 'nome', label: 'Nome', sortable: true },
          { key: 'processadoraDeMargem', label: 'Processadora de Margem', sortable: true },
          { key: 'formato', label: 'Formato' },
        ],
        items: [],
        dados: {},
        acesso: {
          CadastroLayoutModularConsulta: false,
          CadastroLayoutCriarNovo: false,
          CadastroLayoutEditar: false,
          CadastroLayoutExcluir: false,
        },

        // Textos
        tituloModal: 'Cadastro de Layout',
        opcaoDesabilitada: 'Não possui permissão para esta ação!',
        textoCadastroCabecalho: 'Cadastro de campos do cabeçalho do layout',
        textoCadastroCorpo: 'Cadastro de campos do corpo do layout',
        textoCadastroRodape: 'Cadastro de campos do rodapé do layout',
        tituloModalCadastroCampos: 'Cadastro de Campos',
      }
    },
    computed: {
      sortOptions() {
        return this.fields.filter((f) => f.sortable).map((f) => ({ text: f.label, value: f.key }))
      },
    },
    mounted() {
      if (this.userData.role == 'master') {
        this.acesso.CadastroLayoutModularConsulta = true
        this.acesso.CadastroLayoutCriarNovo = true
        this.acesso.CadastroLayoutEditar = true
        this.acesso.CadastroLayoutExcluir = true
      } else {
        this.acesso.CadastroLayoutModularConsulta = this.userData.GrupoAcesso.CadastroLayoutModularConsulta
        this.acesso.CadastroLayoutCriarNovo = this.userData.GrupoAcesso.CadastroLayoutCriarNovo
        this.acesso.CadastroLayoutEditar = this.userData.GrupoAcesso.CadastroLayoutEditar
        this.acesso.CadastroLayoutExcluir = this.userData.GrupoAcesso.CadastroLayoutExcluir
      }
    },
    methods: {
      carregarGrid() {
        this.isBusy = true
        useJwt
          .post('layout/getAll', {
            ...this.pesquisar,
            institutoId: this.userData.institutoSelecionado,
          })
          .then((response) => {
            if (!response.data || response.data.length == 0) return this.$message.error('Não foram encontrados layouts!')

            this.items = response.data
            this.totalRows = response.data.length
          })
          .catch((error) => {
            console.error(error)
            this.$message.error('Erro ao carregar os layouts!')
          })
          .finally(() => {
            this.isBusy = false
          })
      },
      novoRegistro() {
        this.dados = {}
        this.$refs['modal-cadastro'].show()
      },
      authEExclui(item) {
        this.items.item = item
        this.items.mode = 'authEExclui'
        this.$refs['modal-auth-senha'].show()
      },
      autenticarMethod(data) {
        if (data) {
          this.$refs['modal-auth-senha'].hide()

          if (this.items.mode == 'authEExclui') {
            this.excluir(this.items.item)
          }
        }
      },
      excluir(item) {
        this.isBusy = true
        useJwt
          .delete('layout', item)
          .then(() => {
            this.$message.success('Layout excluído com sucesso!')
            this.items = []
            this.totalRows = this.items.length
          })
          .catch((error) => {
            console.error(error)
            this.$message.error('Erro ao excluir layout!')
          })
          .finally(() => {
            this.carregarGrid()
            this.isBusy = false
          })
      },
      editar(item) {
        this.dados = {
          id: item.id,
          tipo: item.tipo,
          formato: item.formato,
          nome: item.nome,
          processadoraDeMargem: item.processadoraDeMargem,
          institutoId: this.userData.institutoSelecionado,
          possuiCabecalho: item.possuiCabecalho,
          possuiRodape: item.possuiRodape,
        }
        this.$refs['modal-cadastro'].show()
      },
      abrirCadastroCabecalho(layoutId) {
        this.tituloModalCadastroCampos = 'Cadastro de Campos do Cabeçalho'
        this.origem = 'cabecalho'
        this.layoutId = layoutId
        this.$refs.modalCadastroCampos.show()
      },
      abrirCadastroCorpo(layoutId) {
        this.tituloModalCadastroCampos = 'Cadastro de Campos do Corpo'
        this.origem = 'corpo'
        this.layoutId = layoutId
        this.$refs.modalCadastroCampos.show()
      },
      abrirCadastroRodape(layoutId) {
        this.tituloModalCadastroCampos = 'Cadastro de Campos do Rodapé'
        this.origem = 'rodape'
        this.layoutId = layoutId
        this.$refs.modalCadastroCampos.show()
      },
      fecharModal() {
        this.$refs['modal-cadastro'].hide()
        this.carregarGrid()
      },
      fecharModalCadastroDeCampos() {
        this.$refs.modalCadastroCampos.hide()
      },
    },
  }
</script>

<style lang="scss" scoped>
  @import '@core/scss/vue/libs/vue-select.scss';

  .botaoPesquisar {
    margin-top: 25px;
    width: 160px;
  }

  #modalCadastroCampos .modal-dialog {
    margin-left: 300px !important;
    margin-right: 300px !important;
    min-width: 99%;
  }
</style>
